import React from 'react';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getNextTheme = (theme) => {
  return theme === 'light' ? 'dark' : 'light';
};

export const Toggle = () => {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <button
          className="mode-toggle"
          onClick={() => {
            toggleTheme(getNextTheme(theme));
          }}
          aria-label={`Switch to ${getNextTheme(theme)} mode`}
        >
          <FontAwesomeIcon icon={theme === 'dark' ? faSun : faMoon} />
        </button>
      )}
    </ThemeToggler>
  );
};
